@import 'styles/application'

.category-filter
  display: flex
  justify-content: flex-end
  margin-bottom: $m-base
  .mantine-SegmentedControl-root
    background-color: transparent

    .mantine-SegmentedControl-active
      background-color: $color-primary

    .mantine-SegmentedControl-label
      color: $color-white-label
      font-family: Poppins

    .mantine-SegmentedControl-labelActive
      background-color: $color-primary
