@import 'styles/application'

.survey-response
  margin-left: $m-xl
  margin-right: $m-xl

.app-shell .survey-response
  margin-left: 0
  margin-right: 0

@include media-breakpoint-down(sm)

  .survey-response
    margin-left: $m-base
    margin-right: $m-base
