@import 'styles/application'

.results
  width: 60vw
  margin: $m-lg auto

  &__info
    color: $color-white26
    font-size: $font-size-base
    font-weight: $font-weight-600
    line-height: $line-height-base
    letter-spacing: -0.0025em

  &__survey-name
    font-size: $font-size-h3
    font-weight: $font-weight-600
    line-height: $line-height-lg
    letter-spacing: -0.0025em
    margin-bottom: $m-sm

  &__description
    font-size: $font-size-base
    font-weight: $font-weight-regular
    line-height: $line-height-base
    letter-spacing: 0.005em
    margin-bottom: $m-base
    width: 560px

  &__legend
    display: flex
    flex-direction: column
    width: 400px
    margin-bottom: $m-lg

  &__item
    display: flex
    height: 40px
    align-items: center

  &__item-name
    flex: 1 1 40px
    font-size: $font-size-base
    color: $color-white-text
    font-weight: $font-weight-600
    line-height: $line-height-base
    letter-spacing: 0em
    margin-right: $m-base
    flex-wrap: nowrap
    +ellipsis

  &__icon
    display: flex
    justify-content: flex-start
    align-items: center
    width: 40px

@include media-breakpoint-down(sm)

  .results
    margin: $m-md 0
    width: 100%

    &__legend
      width: 100%

    &__survey-name
      font-size: $font-size-h4
      line-height: $line-height-md
      margin-bottom: $m-xs

    &__description
      width: 100%
