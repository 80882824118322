@import 'vendor/normalize'
@import 'base/breakpoints'
@import 'base/variables'
@import 'base/mixins'

.app
  overflow: hidden

.app-shell
  main
    margin-left: $m-xl
    margin-right: $m-xl
    padding: 0

.svg-background
  position: absolute
  top: 0
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  display: flex
  justify-content: center
  z-index: -1

  .svg-content 
    width: 84vh
    height: 84vh

.trapeze
  transform: rotate(45deg)

@include media-breakpoint-down(sm)
  .app-shell
    main
      margin: 0 auto
      padding: 0 $m-base
