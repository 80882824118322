@import 'styles/application'

.company-table
  background-color: $color-black

  &__wrapper
    overflow: scroll

  &__content
    display: flex
    gap: $m-xs

  &__badge
    border-color: $color-white
    color: $color-white

  &__badge-wrapper
    display: flex
    align-items: center

  &__team-name
    color: $color-primary

  &__pending
    color: #FFD43B

  &__x
    border: none
    background-color: unset
    color: $color-white
    padding: 0
    cursor: pointer
    margin-left: $m-xs
