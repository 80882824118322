@import 'styles/application'

.back
  &__wrapper
    align-items: center
    margin-top: $m-md
    width: 100%
    display: flex
    flex-direction: row
    cursor: pointer

    .left-arrow
      position: relative
      left: 0

    &:hover .left-arrow
      left: -10px
      transition: left 0.2s ease-out

    span
      color: $color-primary
      margin-left: $m-xs
      font-weight: $font-weight-600
      font-size: $font-size-base
      line-height: $line-height-base

@include media-breakpoint-down(sm)

  .back
    &__wrapper
      margin-top: $m-md

      span
        font-size: $font-size-sm
