@import 'styles/application'

.company-form

  &__input
    margin-bottom: $m-base
    position: relative

  &__input:focus-within
    .company-form__input-area
      border-color: $color-primary !important

  &__input-area
    background-color: $color-background
    border: 1px solid $color-white
    border-radius: $border-radius-sm

  &__label
    font-size: $font-size-xs
    position: absolute
    z-index: 1
    background-color: $color-background
    padding: 0 5px
    top: -10px
    margin-left: 5px

  &__dropdown
    background-color: $color-bglight
    border-radius: $border-radius-sm
    margin-top: 5px

  &__color
    width: 100%

  &__color-label
    position: absolute
    font-size: $font-size-xs
    font-weight: $font-weight-500
    z-index: 1
    background-color: $color-background
    padding: 2px 5px
    border-radius: 0 0 $border-radius-sm 0

  &__submit
    margin-top: $m-base
