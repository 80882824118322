@import 'styles/application'

.results-list
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &__headline
    font-size: $font-size-h1
    line-height: $line-height-lg
    margin-top: $m-2xl
    margin-bottom: $m-lg

  &__surveys
    list-style-type: none
    flex-direction: column
    display: flex
    width: 100%
    align-items: center
    padding: 0
    margin: 0

  &__survey
    align-items: center
    border-radius: $border-radius-base
    border: 1px solid $color-white26
    box-sizing: border-box
    cursor: pointer
    display: flex
    flex-direction: row
    padding: $m-base
    margin-bottom: $m-sm
    width: 560px
    transition: background-color 300ms ease-in-out

    &:hover
      background-color: $color-white10

  &__name
    margin-left: $m-sm
    font-weight: $font-weight-500
    font-size: $font-size-h5
    line-height: $line-height-base
    letter-spacing: -0.25%
    position: relative

@include media-breakpoint-down(sm)

  .results-list

    &__headline
      font-size: $font-size-h2

    &__survey
      width: 100%
      padding: $m-sm

    &__name
      font-size: $font-size-base

.app-shell .results-list
  margin-left: 0
  margin-right: 0
