//----- fonts sizes and line-heights -----------------------------------------------------------------------------------
$font-size-xs:                    12px
$font-size-sm:                    14px
$font-size-base:                  16px
$font-size-h5:                    $font-size-base * 1.25
$font-size-h4:                    $font-size-h5 * 1.25
$font-size-h3:                    $font-size-h4 * 1.25
$font-size-h2:                    $font-size-h3 * 1.25
$font-size-h1:                    $font-size-h2 * 1.25
$font-size-big-paragraph:         18px

//----- spacings (margins/paddings) ------------------------------------------------------------------------------------
$m-xs:                            8px
$m-sm:                            $m-xs * 2
$m-base:                          $m-xs * 3
$m-md:                            $m-xs * 4
$m-lg:                            $m-base * 2
$m-xl:                            $m-base * 3
$m-2xl:                           $m-base * 4

$login-gateway-width:             356px
$letter-spacing:                  .15px

//----- line heights ---------------------------------------------------------------------------------------------------
$line-height-sm:                  $m-sm
$line-height-base:                $m-base
$line-height-md:                  $m-md
$line-height-lg:                  $m-lg
$text-area-height:                200px

//----- border radiuses ------------------------------------------------------------------------------------------------
$border-radius-xs:                8px
$border-radius-sm:                10px
$border-radius-base:              12px
$border-radius-round:             100%


//----- breakpoints ----------------------------------------------------------------------------------------------------
$grid-breakpoints:                (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)

$grid-columns:                    12
$grid-gutter-width:               $m-base

$container:                       map-get($grid-breakpoints, xl)

//----- colors ---------------------------------------------------------------------------------------------------------
$color-primary:                   #32A89C
$color-primary-dark:              #00635A
$color-white:                     #fffefce0
$color-white-text:                #ffffffcc
$color-white-label:               #ffffffa3
$color-black:                     #0A0B0B
$color-background:                #121212
$color-bglight:                   #202020
$color-white26:                   #ffffff42
$color-white10:                   #FFFFFF1A
$color-white5:                    #FFFFFF0D
$color-switch:                    #7643A8
$color-white10:                   #FFFFFF1A
$color-tomato:                    #c99284
$color-purple:                    #7643A8
$color-purple-dark:               #53297c
$color-silver:                    #c9c9c9
$color-silver-dark:               #8a8a8a
$color-red:                       #fc6969
$color-red-dark:                  #a54343
$color-trend-primary:             #48bd66
$color-trend-secondary:           #FEC92D
$color-danger:                    #ff0000

//----- fonts ----------------------------------------------------------------------------------------------------------
$font-family-header:              'Rubik'
$font-family-body:                'Nunito Sans'
$font-stack-header:               $font-family-header, sans-serif
$font-stack-body:                 $font-family-body, serif

$font-weight-light:               300
$font-weight-regular:             400
$font-weight-500:                 500
$font-weight-600:                 600
$font-weight-bold:                700

$icon-size:                       $m-base

//----- transitions ----------------------------------------------------------------------------------------------------
$transition-duration:             .3s
$transition-function:              ease-in-out

//----- z-indexes ------------------------------------------------------------------------------------------------------
$z-negative:                      -1
$z-lower:                         30
$z-low:                           40
$z-base:                          50
$z-high:                          60
$z-higher:                        70


$dark-background: #343434 0% 0% no-repeat padding-box
$font-family: Poppins
$font-family-code-markdown: Consolas, Monaco, "Andale Mono", monospace
$font-family-default: sans-serif
