@import 'styles/application'

.loading
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &__headline
    font-size: $font-size-h1
    line-height: $line-height-lg
    margin-top: $m-2xl
    margin-bottom: $m-lg
