@import 'styles/application'

.cookie-policy-banner
  display: flex
  position: fixed
  background: $color-bglight
  color: $color-white-text
  font-size: $font-size-sm
  left: 0
  bottom: 0
  width: 100%
  z-index: 9999

  &__content
    display: flex
    justify-content: space-between
    align-items: center
    gap: $m-base
    padding: $m-base
    width: 100%

    a
      cursor: pointer

      &:hover
        color: $color-primary

    button
      background: none
      border: none
      padding: 0
      color: $color-white-text
      cursor: pointer

      &:hover
        color: $color-primary
