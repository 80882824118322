@import 'styles/application'

.responses
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-left: $m-xl
  margin-right: $m-xl

  &__headline
    font-size: $font-size-h1
    line-height: $line-height-lg
    margin-top: $m-2xl
    margin-bottom: $m-lg

  &__surveys
    list-style-type: none
    flex-direction: column
    display: flex
    width: 100%
    align-items: center
    padding-left: 0

@include media-breakpoint-down(sm)

  .responses

    &__headline
      font-size: $font-size-h2
