@import 'styles/application'

.welcome-screen
  display: flex
  align-items: center

.login-box
  width: 50%
  text-align: center
  position: relative
  overflow: hidden
  display: flex
  align-items: center

  &:hover .login-background
    transform: scale(1.4)

.login
  display: flex
  flex-direction: column
  font-weight: $font-weight-regular
  height: 100vh
  justify-content: center
  letter-spacing: $letter-spacing
  margin: 0 auto
  max-width: $login-gateway-width
  width: 85%

  &__header
    color: $color-white-label
    font-size: $font-size-h4
    font-weight: $font-weight-regular
    margin-bottom: $m-base

  &__header-span
    color: $color-white
    display: block
    font-size: $font-size-h2
    font-weight: $font-weight-600

  &__text
    color: $color-white-text
    font-size: $font-size-h5
    margin:
      bottom: $m-lg
      top: 0

  &__public
    +transition(background-color)
    border-color: $color-primary
    border-radius: $border-radius-sm
    color: $color-primary
    font-weight: $font-weight-500
    font-size: $font-size-base
    margin: 10px auto
    padding: $m-xs $m-sm
    height: 48px
    position: absolute
    top: 3rem
    right: 3rem

  &__button
    +transition(background-color)
    background-color: $color-primary
    border: none
    border-radius: $border-radius-sm
    color: $color-background
    cursor: pointer
    display: flex
    font-weight: $font-weight-bold
    font-size: $font-size-base
    margin: 0 auto
    padding: $m-xs $m-sm
    width: fit-content
    height: 48px

    &:focus
      outline: none

    &:hover
      background-color: $color-primary-dark

  .--manager
    background-color: $color-purple

    &:hover
      background-color: $color-purple-dark

.login-background
  position: absolute
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  display: flex
  justify-content: center
  z-index: -1
  transition-duration: 400ms

  svg
    width: 50%
    height: 50%

.login-box-manager:hover ~ .thunder path
  stroke: $color-purple

.login-box-employee:hover ~ .thunder path
  stroke: $color-primary

.thunder
  position: absolute
  left: 0
  right: 0
  height: 100%
  margin-left: auto
  margin-right: auto
  display: flex
  justify-content: center
  z-index: -1

  path
    stroke: $color-white
    transition-duration: 400ms

@include media-breakpoint-down(sm)

  .welcome-screen
    display: block

  .login-box
    width: 100%
    height: 50vh

  .thunder
    display: none
