@import 'styles/application'

.header
  border-bottom: 2px $color-white5 solid
  display: flex
  margin: 0 $m-lg
  justify-content: space-between

  &__branding
    display: flex
    align-items: center
    cursor: pointer

  &__menu
    align-items: center
    align-self: center
    border-radius: $border-radius-base
    border: 1px solid $color-white26
    box-sizing: border-box
    display: flex
    padding: $m-xs $m-sm
    position: relative
    transition: background-color 300ms ease-in-out

    &:hover
      background-color: $color-white10

      .header__submenu
        display: flex

  &__avatar
    width: 42px
    height: 42px
    border-radius: $border-radius-round
    margin-right: $m-sm

  &__email
    font-size: $font-size-sm
    line-height: $line-height-base
    font-weight: $font-weight-bold
    margin-right: 4px
    flex: 1

  &__submenu
    position: absolute
    width: 100%
    min-height: 60px
    top: 58px
    right: 0px
    z-index: 50
    display: none
    background-color: $color-bglight
    border: 0.25px solid $color-white26
    box-sizing: border-box
    border-radius: $border-radius-base
    padding: $m-sm 0
    flex-direction: column

  &__button
    all: unset
    padding: $m-xs $m-sm
    position: relative
    display: flex
    align-items: center
    transition: padding-left 300ms ease-in
    +ellipsis
    cursor: pointer

    &:hover
      background-color: $color-white10
      padding-left: $m-md

  &__name
    font-size: $font-size-sm
    font-weight: $font-weight-regular
    color: rgba(255, 255, 255, 0.64)
    flex: 1
    text-align: left

  &__icon
    flex: 0 0 $m-md
    display: flex
    justify-content: center

@include media-breakpoint-down(sm)

  .header
    margin: 0 $m-base

    &__menu
      min-width: 50px
      border: none
      padding: $m-xs

    &__submenu
      width: 80vw

    &__avatar
      margin-right: 0

    &__email
      display: none

    &__arrow
      display: none
