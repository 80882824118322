@import 'styles/application'

.response
  width: 60vw
  margin: $m-lg auto

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: $m-sm

  &__name
    font-weight: $font-weight-regular
    font-size: $font-size-base
    color: $color-white26

  &__index
    font-weight: $font-weight-regular
    font-size: $font-size-base
    display: flex
    justify-content: flex-end

  &__title
    font-size: $font-size-h4
    line-height: $line-height-md
    font-weight: $font-weight-600
    margin-top: $m-lg
    margin-bottom: $m-2xl
    color: $color-white-text

  &__answers
    display: flex
    justify-content: space-between
    height: 110px
    position: relative
    margin: 0 $m-md
    margin-bottom: $m-xl

    &::after
      border-top: 5px solid $color-white26
      position: absolute
      content: ""
      z-index: -1
      width: 100%
      left: 0px
      top: 82px

  &__wrapper
    display: flex
    flex-direction: column
    align-items: center
    gap: 21px
    position: absolute
    transform: translateX(-50%)

  .offset

    &-0
      left: 0%

    &-25
      left: 25%

    &-50
      left: 50%

    &-75
      left: 75%

    &-100
      left: 100%

  &__label
    display: flex
    max-width: 35px
    justify-content: center
    text-align: center
    align-items: center
    height: 50px
    cursor: pointer

  &__label:hover + &__input
    border: 4px solid $color-primary

  &__input
    width: 28px
    height: 28px
    border-radius: $border-radius-round
    background-color: $color-background
    border: 4px solid $color-white26
    cursor: pointer

    &:hover
      border: 4px solid $color-primary

  &--checked
    border: 8px solid $color-primary
    width: 35px
    height: 35px
    border-radius: $border-radius-round
    left: 25%
    bottom: 8px
    position: absolute
    transform: translateX(-50%)
    transition: left 300ms ease-out
    cursor: pointer

  &__nav
    display: flex
    justify-content: space-between

  &__button
    display: flex
    border: 1px solid $color-white-text
    background-color: $color-background
    border-radius: $border-radius-base
    color: $color-white-text
    padding: 12px 16px
    font-size: $font-size-base
    gap: 8px
    cursor: pointer

    &--submit
      background-color: $color-background
      color: $color-trend-primary
      border: 1px solid $color-trend-primary
      padding: 12px 16px
      font-size: $font-size-base
      border-radius: $border-radius-base
      cursor: pointer

  .--previous
    .left-arrow
      position: relative
      left: 0

    &:hover .left-arrow
      left: -8px
      transition: left 300ms ease-out

  .--next
    border: 1px solid $color-primary
    background-color: $color-background
    color: $color-primary
    animation: next 300ms

    .right-arrow
      position: relative
      left: 0

    &:hover .right-arrow
      left: 8px
      transition: left 300ms ease-out

// animations

@keyframes next
  0%
    width: 0
  100%
    width: 100%

@include media-breakpoint-down(sm)

  .response
    margin: $m-md 0
    width: 100%

    &__title
      font-size: $font-size-h5
      margin-bottom: $m-md

    &__answers
      height: 200px
      margin-left: 0
      margin-bottom: $m-2xl

      &::after
        display: none

    &__wrapper
      flex-direction: row-reverse
      justify-content: flex-end
      transform: none

    .offset

      &-0
        top: 0%
        left: 0

      &-25
        top: 25%
        left: 0

      &-50
        top: 50%
        left: 0

      &-75
        top: 75%
        left: 0

      &-100
        top: 100%
        left: 0

    &__label
      max-width: none
      height: 35px

    &--checked
      transition: top 300ms ease-out
      transform: none
      margin-left: -3px
