@import 'styles/application'

.animation
  height: 100vh
  max-height: 100vh
  max-width: 100%
  overflow: auto

  &::-webkit-scrollbar 
    width: 12px

  &::-webkit-scrollbar-thumb 
    background-color: $color-bglight
    border-radius: $border-radius-sm

  &__floating-button
    position: fixed
    bottom: 100px
    right: 50px
    font-size: 16px

@media (min-height: 1050px) 
  .animation
    overflow: hidden

    &::-webkit-scrollbar 
      width: 12px

    &::-webkit-scrollbar-thumb 
      background-color: $color-bglight
      border-radius: $border-radius-sm
