@import 'styles/application'

.surveys-panel
  margin-top: $m-base

  &__header
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    gap: $m-base

  &__buttons
    display: flex
    gap: $m-base

  &__title
    font-size: $font-size-h3

  &__add-button
    flex: 0

  &__table-wrapper
    margin: $m-base 0

  &__empty
    font-weight: $font-weight-bold

@include media-breakpoint-down(sm)

  .surveys-panel

    &__header
      gap: $m-xs

    &__buttons
      flex-wrap: wrap
      gap: $m-sm
