@import 'styles/application'

.breadcrumbs
  display: flex
  flex-wrap: wrap
  width: 100%
  margin-top: $m-sm
  gap: $m-xs

  &__item
    color: $color-white26
    font-weight: $font-weight-600
    font-size: $font-size-sm
    cursor: pointer

  &__separator
    color: $color-white26
    margin: 0
