@import 'styles/application'

.email
  &-form__submitButton
    margin-top: $m-base

  &-items
    display: flex
    flex-direction: row
    flex-wrap: wrap
    margin-bottom: $m-xs

  &-item
    margin-bottom: $m-xs
    margin-right: $m-xs

    .mantine-Chip-label
      cursor: auto

    &__delete
      background-color: $color-white26
      border-radius: $border-radius-round
      margin-left: $m-xs
      border: none
      cursor: pointer

  &-input
    background-color: $color-background
    border: 1px solid $color-white
    border-radius: $border-radius-sm
    margin-bottom: $m-xs

  &-list
    border: 1px solid $color-white
    border-radius: $border-radius-sm
    padding: 10px 5px

    &__container
      max-height: 300px
      overflow: scroll
      overflow-x: hidden

      &::-webkit-scrollbar 
        width: 12px
      

      &::-webkit-scrollbar-thumb 
        background-color: $color-bglight
        border-radius: $border-radius-sm
      

    &__list-button
      align-items: center
      background-color: $color-background
      cursor: pointer
      display: flex
      justify-content: space-between
      padding: 0 $m-xs

    &-empty
      text-align: center

    &__list-item
      padding: $m-xs
      position: relative

      &:not(:last-child):before
        background-color: $color-bglight
        bottom: 0
        content: ''
        height: 1px
        left: 50%
        position: absolute
        transform: translateX(-50%)
        width: 95%

    &__checkbox
      margin-right: $m-xs
      cursor: pointer
    
    &__label 
      cursor: pointer

.chevron-icon
  transition: rotate .4s ease

  &-active
    rotate: 180deg

.mantine-TextInput-error 
  white-space: pre-wrap
