@import 'styles/application'

.survey-item

  td
    +ellipsis

  &__buttons
    display: flex
    flex-wrap: wrap
    gap: $m-xs

  &__new-name
    margin-bottom: $m-base

@include media-breakpoint-down(sm)

  .survey-item

    &__buttons
      flex-wrap: nowrap
