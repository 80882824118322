@import 'styles/application'

.switch
  height: 24px
  width: 44px
  border-radius: $border-radius-base
  background-color: $color-white10
  cursor: pointer
  display: flex
  position: relative
  transition: background-color 300ms ease-in-out

  &__point
    position: absolute
    background: $color-bglight
    width: 22px
    height: 22px
    border-radius: $border-radius-round
    top: 1px
    left: 0px
    box-shadow: 0 0 0 2px $color-white26
    border: 2px solid $color-white26
    transition: all 300ms ease-in-out

  &--active
    background: $color-switch

    .switch__point
      box-shadow: none
      border: none
      left: 22px
      width: 20px
      height: 20px
      top: 2px
