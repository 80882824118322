@import 'styles/application'

.team-results
  background-color: $color-white5
  padding: $m-xs $m-sm
  margin-left: -$m-sm
  border-radius: $border-radius-base
  margin-bottom: $m-base
  width: 436px
  border: 1px solid $color-white10

  &__header
    color: $color-white-text
    margin-bottom: $m-xs

@include media-breakpoint-down(sm)

  .team-results
    width: 100%
    margin-left: 0
