@import 'styles/application'

.profile
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &__wrapper
    display: flex
    justify-content: space-between
    width: 800px
    align-items: center

  &__headline
    font-size: $font-size-h1
    line-height: $line-height-lg
    margin-top: $m-2xl
    margin-bottom: $m-lg

  &__progress
    position: relative
    height: 300px

  &__circle-wrapper
    overflow: hidden
    position: absolute
    bottom: 0
    transition: height 500ms
    transform: rotate(180deg)

  &__circle-filled
    height: 300px

  &__details
    list-style-type: none
    flex-direction: column
    display: flex
    width: 50%
    align-items: center
    padding-left: 0

  &__detail
    align-items: center
    box-sizing: border-box
    display: flex
    flex-direction: row
    margin-bottom: $m-xs
    padding: 0 $m-base
    position: relative

  &__select
    align-items: center
    justify-content: space-between
    font-size: $font-size-base
    height: 48px
    font-weight: $font-weight-regular
    width: 240px
    border: 1px solid $color-white
    border-radius: $border-radius-sm
    padding: $m-xs $m-sm
    background-color: $color-background
    margin-bottom: $m-xs

  &__select--dropdown
    background-color: $color-bglight
    border-radius: $border-radius-sm

  &__select--root:focus-within

    .profile__select--label
      color: $color-primary

    .profile__select
      border-color: $color-primary !important

  &__select--label
    text-transform: capitalize
    font-weight: $font-weight-500
    font-size: $font-size-xs
    line-height: $line-height-base
    padding: 0 $m-xs
    top: -12px
    left: 40px
    position: absolute
    z-index: 1
    background-color: $color-background

  &__footer
    margin-top: $m-lg

  &__message
    font-weight: $font-weight-600
    font-size: $font-size-h5

  &__back
    margin: 0 auto
    width: 200px
    margin-bottom: $m-2xl

@include media-breakpoint-down(sm)

  .profile
    margin-bottom: 40%

    &__wrapper
      width: 100%
      position: relative
      flex-direction: column

    &__progress
      height: 150px
      margin-bottom: $m-md
      display: flex
      justify-content: center

    &__circle-filled
      height: 150px

    &__footer
      margin-top: $m-base
