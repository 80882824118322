@import 'styles/application'

.company-panel
  margin-bottom: $m-lg

  &__header
    display: flex
    justify-content: space-between
    gap: $m-base
    margin-top: $m-base

  &__title
    font-size: $font-size-h3

  &__description
    margin: $m-base 0
    font-size: $font-size-base

  &__description-header
    color: $color-primary
    display: block

  &__domain
    font-size: $font-size-base
    margin-bottom: $m-base

  &__domain-header
    color: $color-primary
    display: block

  &__company-shape
    margin-left: $m-sm

  &__buttons
    display: flex
    gap: $m-base

  &__add-button
    flex: 0

  &__team-buttons
    margin-top: $m-base
    display: grid
    grid-template-columns: repeat(auto-fit, 150px)
    gap: $m-base

  &__team-button
    background-color: unset
    border: 1px solid $color-white
    border-radius: $border-radius-sm
    height: 150px
    width: 150px

    &:hover
      background-color: $color-black
      border-color: $color-primary

  &__add-user-button
    margin-top: $m-base

.teams
  margin-top: $m-base
  display: flex
  align-content: center
  justify-content: space-between

@include media-breakpoint-down(sm)

  .company-panel

    &__header
      flex-direction: column
      gap: $m-xs

    &__title
      display: flex
      flex-direction: column
      font-size: $font-size-h4

      &__separator
        display: none

      &__company-name
        display: flex
        align-items: center

    &__buttons
      flex-wrap: wrap
      gap: $m-sm

    &__team-buttons
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))

    &__team-button
      width: 100%
