@import 'styles/application'

.responses
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &__headline
    font-size: $font-size-h1
    line-height: $line-height-lg
    margin-top: $m-2xl
    margin-bottom: $m-lg

  &__surveys
    list-style-type: none
    flex-direction: column
    display: flex
    width: 100%
    align-items: center
    padding: 0
    margin: 0

.app-shell .responses
  margin-left: 0
  margin-right: 0

@include media-breakpoint-down(sm)

  .responses
    margin: 0 $m-base

    &__headline
      font-size: $font-size-h2
