@import 'styles/application'

.filterset-modal
  margin-top: 0
  width: 800px

  &__header
    position: absolute
    right: 20px
    top: 20px
    margin: 0
    display: block

  &__wrapper
    margin-top: 0

.filters
  width: 400px
  margin: $m-base 0

  &__item
    border-top: 1px solid $color-white10
    display: flex
    align-items: center
    border: none
    height: 40px

  &__selects
    width: 100%

  &__name
    flex: 1 1 40px
    font-size: $font-size-base
    font-weight: $font-weight-600
    line-height: $line-height-base
    letter-spacing: 0em
    margin-right: $m-base
    flex-wrap: nowrap
    cursor: pointer

    &--cursor-default
      cursor: default

  &__icon
    display: flex
    justify-content: flex-start
    align-items: center
    width: 40px

  &__name
    position: relative
    width: 100%

  &__name:focus-within

    .filters__name-input
      border-color: $color-primary !important

  &__name-input
    background-color: $color-background
    border: 1px solid $color-white
    border-radius: $border-radius-sm
    height: 48px

  &__name-label
    margin: $m-xs 0
    font-size: $font-size-xs
    line-height: $line-height-base
    padding: 0 $m-xs
    top: -20px
    left: 10px
    position: absolute
    z-index: 1
    background-color: $color-background

  &__title
    margin-bottom: $m-base

  &__shape-wrapper
    display: flex
    align-content: center
    justify-content: space-between

  &__shape
    margin-top: $m-base
    display: flex
    align-items: center
    position: relative

  &__shape:focus-within

    .filters__shape-input
      border-color: $color-primary !important

  &__shape-input
    background-color: $color-background
    border: 1px solid $color-white
    border-radius: $border-radius-sm
    height: 48px

  &__shape-label
    margin: $m-xs 0
    font-size: $font-size-xs
    line-height: $line-height-base
    padding: 0 $m-xs
    top: -20px
    left: 10px
    position: absolute
    z-index: 1
    background-color: $color-background

  &__color
    position: relative
    margin-top: $m-base

  &__color-label
    font-size: $font-size-xs
    font-weight: $font-weight-500
    position: absolute
    z-index: 1
    top: -5px
    background-color: $color-background
    padding: 5px
    border-radius: 0 0 $border-radius-sm 0

  &__color-picker
    width: 100%

  &__appearance
    width: 35%

  &__data
    width: 60%

  &__filter
    margin: $m-xs 0
    position: relative

  &__filter:focus-within

    .filters__filter-input
      border-color: $color-primary !important

  &__filter label
    margin: $m-xs 0
    font-size: $font-size-xs
    line-height: $line-height-base
    padding: 0 $m-xs
    top: -20px
    left: 10px
    position: absolute
    z-index: 1
    background-color: $color-background

  &__filter-input
    font-size: $font-size-base
    font-weight: $font-weight-regular
    border: 1px solid $color-white
    border-radius: $border-radius-sm
    background-color: $color-background
    margin-bottom: $m-xs
    padding: 6px $m-xs
    height: auto

  &__filter-label
    text-transform: capitalize
    font-size: $font-size-sm

  &__filter-placeholder
    text-transform: capitalize

  &__filter-values
    margin-left: 0

  &__filter-value
    border-radius: $border-radius-xs
    border: 1px solid $color-white
    margin-left: 5px
    height: 25px

  &__filter-dropdown
    margin-top: -5px
    background-color: $color-bglight
    border-radius: $border-radius-sm

  &__new
    background-color: $color-background
    color: $color-primary
    font-size: $font-size-base
    text-decoration: underline
    padding: 0
    margin-left: 0
    margin-top: $m-xs

    &:hover
      background-color: $color-background

  &__info
    display: flex
    gap: 20px
    margin-bottom: $m-xs
    align-items: center
    margin-top: -$m-lg

  &__buttons-wrapper
    margin-top: 0

  &__config
    display: flex
    justify-content: space-between

  &__footer
    display: flex
    justify-content: space-between
    margin-top: $m-md

  &__button
    background-color: $color-primary
    border: none
    border-radius: $border-radius-xs
    color: $color-background
    cursor: pointer
    display: flex
    font-weight: $font-weight-500
    font-size: $font-size-base
    padding: $m-xs $m-md
    width: -moz-fit-content
    width: fit-content

    &:hover
      background-color: $color-primary-dark

  &__delete
    background-color: $color-red
    padding: $m-xs $m-base

    &:hover
      background-color: $color-red-dark

  &__warning
    margin-right: $m-xs

.mantine-MultiSelect-rightSection
  top: -10px
  right: 5px

@include media-breakpoint-down(sm)

  .filterset-modal
    padding: 0

  .filters
    width: 100%

    &__config
      display: flex
      flex-direction: column

    &__appearance
      width: 100%

    &__data
      width: 100%
      margin-top: $m-base

    &__footer
      margin-top: $m-sm
