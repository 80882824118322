@import 'styles/application'

.response-item
  align-items: center
  border-radius: $border-radius-base
  border: 1px solid $color-white26
  box-sizing: border-box
  cursor: pointer
  display: flex
  flex-direction: row
  padding: $m-base
  margin-bottom: $m-sm
  width: 560px
  transition: background-color 300ms ease-in-out

  &:hover
    background-color: $color-white10

    .response-item__status-wrapper
      left: 10px
      transition: left 300ms linear

  &__name
    margin-left: $m-sm
    font-weight: $font-weight-500
    font-size: $font-size-h5
    line-height: $line-height-base
    letter-spacing: -0.25%
    position: relative

  &__new-indicator
    text-transform: uppercase
    position: absolute
    font-family: Poppins
    font-size: 10px
    font-weight: $font-weight-bold
    line-height: $line-height-sm
    letter-spacing: -0.0625em
    color: $color-primary
    top: -10px
    right: 0px

  &__status-wrapper
    flex: 1
    display: flex
    justify-content: flex-end
    align-items: center
    margin-right: 0px
    position: relative
    left: 0
    transition: left 300ms linear

  &__status
    display: flex
    align-items: center
    justify-content: space-between
    font-size: $font-size-xs
    font-weight: $font-weight-600
    line-height: $line-height-base
    letter-spacing: 0em
    color: $color-primary
    gap: 8px

@include media-breakpoint-down(sm)

  .response-item
    width: 100%
    padding: $m-sm

    &__name
      font-size: $font-size-base

    &__status-text
      display: none
