@import 'styles/application'

.modal
  margin-top: 30vh
  border: 1px solid $color-white-label
  width: 560px
  border-radius: $border-radius-xs

  &__header
    display: none

  &__content
    padding: $m-sm $m-base

  &__msg
    font-size: $font-size-base
    line-height: $line-height-base

  &__wrapper
    margin-top: $m-md
    display: flex
    gap: 10px

  &__button
    padding: $m-xs $m-sm
    font-size: $font-size-sm
    font-weight: $font-weight-600
    color: #000
    border-radius: $border-radius-sm

  &__confirm
    background-color: $color-primary

    &:hover
      background-color: $color-primary-dark

  &__cancel
    background-color: $color-silver

    &:hover
      background-color: $color-silver-dark
