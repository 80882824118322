@import 'styles/application'

.skeleton
  width: 560px
  height: 74px
  border-radius: $border-radius-base
  margin-bottom: $m-xs

  &--full-width
    width: 100%

@include media-breakpoint-down(sm)

  .skeleton
    width: 100%
    height: 58px
