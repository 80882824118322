@import 'styles/application'

.onboarding-profile-modal
  &__button
    +transition(background-color)
    background-color: $color-primary
    border: none
    border-radius: $border-radius-sm
    color: $color-background
    cursor: pointer
    display: flex
    font-weight: $font-weight-bold
    font-size: $font-size-base
    margin: 0 auto
    padding: $m-xs $m-sm
    width: fit-content
    height: 48px
