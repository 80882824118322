@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints))
    $n: index($breakpoint-names, $name)
    @return if($n !=null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null)

@function breakpoint-min($name, $breakpoints: $grid-breakpoints)
    $min: map-get($breakpoints, $name)
    @return if($min !=0, $min, null)

@function breakpoint-max($name, $breakpoints: $grid-breakpoints)
    $next: breakpoint-next($name, $breakpoints)
    @return if($next, breakpoint-min($next, $breakpoints) - .02, null)

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints)
    @return if(breakpoint-min($name, $breakpoints)==null, "", "-#{$name}")

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints)
    $min: breakpoint-min($name, $breakpoints)

    @if $min
        @media (min-width: $min)
            @content

    @else
        @content

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints)
    $max: breakpoint-max($name, $breakpoints)

    @if $max
        @media (max-width: $max)
            @content
    @else
        @content

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints)
    $min: breakpoint-min($lower, $breakpoints)
    $max: breakpoint-max($upper, $breakpoints)

    @if $min !=null and $max !=null
        @media (min-width: $min) and (max-width: $max)
            @content
    @else if $max==null
        @include media-breakpoint-up($lower, $breakpoints)
            @content
    @else if $min==null
        @include media-breakpoint-down($upper, $breakpoints)
            @content

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints)
    $min: breakpoint-min($name, $breakpoints)
    $max: breakpoint-max($name, $breakpoints)

    @if $min !=null and $max !=null
        @media (min-width: $min) and (max-width: $max)
            @content
    @else if $max==null
        @include media-breakpoint-up($name, $breakpoints)
            @content
    @else if $min==null
        @include media-breakpoint-down($name, $breakpoints)
            @content
