@import 'styles/application'

.chart
  font-size: $font-size-base
  font-weight: $font-weight-600
  line-height: $line-height-base
  letter-spacing: 0.005em

  &__table
    border-collapse: collapse
    border-spacing: 0
    width: 100%

  &__row
    height: 80px
    border-style: none
    font-size: $font-size-sm
    overflow: hidden
    word-break: normal
    line-height: $line-height-sm

  &__left
    text-align: left
    width: 160px
    padding-right: $m-xs

  &__right
    text-align: right
    width: 160px
    padding-left: $m-xs

  &__trend
    position: relative

  &__trend:hover::after
    content: "arcu cursus euismod quis viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat sed cras ornare arcu"
    position: absolute
    top: 100%
    left: 50%
    transform: translateX(-50%)
    min-width: 200px
    max-width: 300px
    padding: $m-xs $m-sm
    text-align: left
    border-radius: $border-radius-xs
    background-color: $color-bglight
    font-size: $font-size-xs
    color: $color-silver-dark
    z-index: 1

@include media-breakpoint-down(sm)

  .chart
    position: relative
    width: 100%

    &__row
      position: relative
      font-size: $font-size-xs
      width: 100%

    &__left
      position: absolute
      width: 100px
      top: 50%
      transform: translateY(-50%)
      left: 0
      padding: 0

    &__right
      position: absolute
      width: 100px
      top: 50%
      transform: translateY(-50%)
      right: 0
      padding: 0
