@import 'styles/application'

.team-panel

  &__header
    display: flex
    gap: $m-base
    margin-top: $m-base

  &__title
    flex: 1

  &__description
    margin: $m-base 0
    font-size: $font-size-base

  &__description-header
    color: $color-primary
    display: block

  &__domain
    font-size: $font-size-base
    margin-bottom: $m-base

  &__domain-header
    color: $color-primary
    display: block

  &__buttons
    display: flex
    gap: $m-base

  &__add-button
    flex: 0

  &__add-user-button
    margin-top: $m-base

  &__empty
    width: 100%
    padding: $m-lg
    font-weight: $font-weight-bold
    text-align: center

  &__team-button
    margin-bottom: $m-xs
    margin-right: $m-xs

  &__team-shape
    margin-left: $m-sm

@include media-breakpoint-down(sm)

  .team-panel

    &__header
      flex-direction: column
      gap: $m-xs

    &__title
      display: flex
      flex-direction: column
      font-size: $font-size-h4

      &__separator
        display: none

      &__team-name
        display: flex
        align-items: center
