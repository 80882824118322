@import 'styles/application'

.loading-failed
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  color: $color-tomato

  &.-info
    color: $color-primary

  &.-danger
    color: $color-danger

  &__headline
    font-size: $font-size-h1
    line-height: $line-height-lg
    margin-top: $m-2xl
    margin-bottom: $m-lg

  &__go-back
    color: $color-white-label
    cursor: pointer
