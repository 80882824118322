@import 'styles/application'

.user-management
  margin-top: $m-base
  display: flex
  flex-direction: column
  gap: $m-base

  &__title
    font-size: $font-size-h3

  &__input
    width: 400px

  &__buttons
    display: flex
    gap: $m-base

@include media-breakpoint-down(sm)

  .user-management
    gap: $m-sm

    &__input
      width: 100%

    &__buttons
      gap: $m-sm
