@import 'styles/application'

.management
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &__company-button
    position: absolute
    margin-top: $m-base
    right: 20px
    top: 0
    font-weight: $font-weight-600
    color: $color-primary
    border: 1px $color-primary solid
    padding: 4px 6px
    border-radius: $border-radius-xs
    cursor: pointer

  &__headline
    font-size: $font-size-h1
    line-height: $line-height-lg
    margin-top: $m-2xl
    margin-bottom: $m-lg

  &__company
    border: 1px solid $color-primary-dark
    padding: $m-sm $m-base
    border-radius: $border-radius-sm
    margin-bottom: $m-base

  &__name
    color: $color-white-label
    font-weight: $font-weight-600
    font-size: $font-size-h5

  &__roles-wrapper
    list-style-type: none
    flex-direction: column
    display: flex
    width: 100%
    align-items: center
    padding-left: 0

  &__role
    justify-content: space-between
    align-items: center
    border-radius: $border-radius-base
    border: 1px solid $color-white26
    box-sizing: border-box
    display: flex
    flex-direction: row
    padding: $m-base
    margin-bottom: $m-sm
    width: 560px
    display: flex
    flex-wrap: wrap
    gap: $m-sm

  &__icon
    font-size: $font-size-xs
    font-weight: $font-weight-600
    border-radius: $border-radius-xs
    padding: 4px 6px
    background-color: $color-white-label
    color: $color-background
    margin-right: $m-xs

  &__title
    flex: 1
    font-weight: $font-weight-600
    color: $color-primary
    text-align: center

  &__results
    flex: 0
    font-weight: $font-weight-600
    color: $color-tomato
    border: 1px $color-tomato solid
    padding: 4px 6px
    border-radius: $border-radius-xs
    cursor: pointer
    margin-right: $m-xs

  &__manage
    flex: 0
    font-weight: $font-weight-600
    color: $color-white-label
    border: 1px $color-white-label solid
    padding: 4px 6px
    border-radius: $border-radius-xs
    cursor: pointer
    margin-right: $m-xs

  &__leave
    flex: 0
    font-weight: $font-weight-600
    color: $color-switch
    border: 1px $color-switch solid
    padding: 4px 6px
    border-radius: $border-radius-xs
    cursor: pointer

@include media-breakpoint-down(sm)

  .management

    &__headline
      font-size: $font-size-h2
      text-align: center

    &__company
      width: 100%
      padding: $m-sm

    &__role
      width: 100%
      padding: $m-sm
      margin-bottom: $m-xs
