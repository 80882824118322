@import 'styles/application'

.survey-edit
  margin-top: $m-base
  overflow-x: scroll

  &::-webkit-scrollbar 
    width: 12px

  &::-webkit-scrollbar-thumb 
    background-color: $color-bglight
    border-radius: $border-radius-sm

  &__title
    margin-bottom: $m-sm
    display: flex
    align-items: center
    gap: $m-xs

  &__add-trend
    margin-top: $m-sm

  &__category-list
    padding: $m-xs

    &__item
      display: flex
      gap: $m-xs
      +ellipsis

  &__add-question
    margin-top: $m-sm

  &__questions-list
    padding: $m-xs

  &__primary
    color: $color-trend-primary

  &__secondary
    color: $color-trend-secondary

  &__primary-icon
    background-color: $color-trend-primary

  &__primary-icon-text
    color: $color-background

  &__secondary-icon
    background-color: $color-trend-secondary

  &__secondary-icon-text
    color: $color-background
