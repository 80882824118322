@import 'styles/application'

.company-table
  background-color: $color-black

  &__wrapper
    overflow: hidden

  &__content
    display: flex
    gap: $m-xs

  &__admin-panel
    display: flex

  &__make-leader
    text-transform: uppercase
